var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, collection, getDocs, getDoc, doc, updateDoc, writeBatch, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
export class OfficeService {
    constructor() {
        this.db = getFirestore();
        this.functions = getFunctions(undefined, 'europe-west3');
    }
    static getInstance() {
        if (!OfficeService.instance) {
            OfficeService.instance = new OfficeService();
        }
        return OfficeService.instance;
    }
    fetchOffices() {
        return __awaiter(this, void 0, void 0, function* () {
            const officesSnapshot = yield getDocs(collection(this.db, 'offices'));
            return officesSnapshot.docs
                .map(doc => (Object.assign({ id: doc.id }, doc.data())))
                .filter(office => office.is_active === true);
        });
    }
    pullOfficeInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const functions = getFunctions(undefined, 'europe-west3');
            const pullOfficeInfo = httpsCallable(functions, 'pullOfficeInfo');
            return pullOfficeInfo();
        });
    }
    fetchAvailableSlots(office, startDate, endDate) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchAvailableSlots = httpsCallable(this.functions, 'fetchAvailableSlots');
            try {
                const result = yield fetchAvailableSlots({
                    officeId: office.id,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                });
                return result.data;
            }
            catch (error) {
                console.error('Error fetching available slots:', error);
                throw error;
            }
        });
    }
    fetchAvailableSlotsForUser(serviceId, userId, startDate, endDate) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchAvailableSlotsForUser = httpsCallable(this.functions, 'fetchAvailableSlotsForUser');
            try {
                const result = yield fetchAvailableSlotsForUser({
                    serviceId,
                    userId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                });
                return result.data;
            }
            catch (error) {
                console.error('Error fetching available slots for user:', error);
                throw error;
            }
        });
    }
    getOfficeById(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            const officeDoc = yield getDoc(officeRef);
            if (officeDoc.exists()) {
                return Object.assign({ id: officeDoc.id }, officeDoc.data());
            }
            else {
                return null;
            }
        });
    }
    fetchAdminOffices(officeRefs) {
        return __awaiter(this, void 0, void 0, function* () {
            // Ensure officeRefs is always an array
            const refsArray = Array.isArray(officeRefs) ? officeRefs : [officeRefs];
            const officePromises = refsArray.map((officeRef) => __awaiter(this, void 0, void 0, function* () {
                const officeDoc = yield getDoc(officeRef);
                if (officeDoc.exists()) {
                    return Object.assign({ id: officeDoc.id }, officeDoc.data());
                }
                return null;
            }));
            const offices = yield Promise.all(officePromises);
            return offices.filter((office) => office !== null);
        });
    }
    updateOffice(updatedOffice) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log('Updating office in OfficeService:', updatedOffice);
                const officeRef = doc(this.db, 'offices', updatedOffice.id);
                const currentOffice = yield getDoc(officeRef);
                if (!currentOffice.exists()) {
                    throw new Error('Office not found');
                }
                const currentOfficeData = currentOffice.data();
                const currentUser = getAuth().currentUser;
                if (!currentUser) {
                    throw new Error('User not authenticated');
                }
                const updateData = Object.assign(Object.assign({}, updatedOffice), { duration: Object.assign(Object.assign({}, updatedOffice.duration), { duration: updatedOffice.duration.duration_without_pause +
                            (updatedOffice.duration.is_pause ? updatedOffice.duration.pause : 0) }) });
                delete updateData.ownerUid;
                if (currentOfficeData.ownerUid !== currentUser.uid) {
                    delete updateData.googleCalendar;
                }
                else if (updateData.googleCalendar === null) {
                    // Если googleCalendar равен null, удаляем это поле из документа
                    updateData.googleCalendar = null;
                }
                yield updateDoc(officeRef, updateData);
                console.log('Office updated successfully');
                // Получаем обновленные данные офиса
                const updatedOfficeDoc = yield getDoc(officeRef);
                return Object.assign({ id: updatedOfficeDoc.id }, updatedOfficeDoc.data());
            }
            catch (error) {
                console.error('Error updating office in Firestore:', error);
                throw error;
            }
        });
    }
    updateOfficeSchedule(officeId, schedule) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            yield updateDoc(officeRef, { schedule });
        });
    }
    getOfficeSchedule(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            const officeDoc = yield getDoc(officeRef);
            if (officeDoc.exists()) {
                const data = officeDoc.data();
                return data.schedule || [];
            }
            return {};
        });
    }
    getOfficeDateOverrides(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverridesRef = collection(this.db, 'offices', officeId, 'dateOverrides');
            const snapshot = yield getDocs(dateOverridesRef);
            return snapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        });
    }
    saveOfficeDateOverrides(officeId, dateOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverridesRef = collection(this.db, 'offices', officeId, 'dateOverrides');
            const batch = writeBatch(this.db);
            for (const override of dateOverrides) {
                const docRef = doc(dateOverridesRef, override.id);
                batch.set(docRef, override);
            }
            yield batch.commit();
        });
    }
    deleteOfficeDateOverride(officeId, dateId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverrideRef = doc(this.db, 'offices', officeId, 'dateOverrides', dateId);
            yield deleteDoc(dateOverrideRef);
        });
    }
}
export default OfficeService;
