import { getFunctions, httpsCallable } from 'firebase/functions';
import { PublicServiceInfo } from '../types/types';

export class PublicServiceInfoService {
  private static instance: PublicServiceInfoService;
  private functions = getFunctions(undefined, 'europe-west3');

  private constructor() {}

  public static getInstance(): PublicServiceInfoService {
    if (!PublicServiceInfoService.instance) {
      PublicServiceInfoService.instance = new PublicServiceInfoService();
    }
    return PublicServiceInfoService.instance;
  }

  public async getPublicServiceInfo(userId: string, serviceId: string): Promise<PublicServiceInfo> {
    const getPublicServiceInfo = httpsCallable(this.functions, 'getPublicServiceInfo');
    const result = await getPublicServiceInfo({ userId, serviceId });
    return result.data as PublicServiceInfo;
  }
}
