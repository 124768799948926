import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Autocomplete, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Client } from 'shared-lib';
import { ClientService } from '../services/ClientService';
import { useAuth } from 'shared-lib'; // Убедитесь, что этот файл существует

interface ClientSelectorProps {
  onClientSelect: (client: Client | null) => void;
  selectedClient: Client | null;
}

const ClientSelector: React.FC<ClientSelectorProps> = ({ onClientSelect, selectedClient }) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [newClient, setNewClient] = useState<Omit<Client, 'id' | 'uid'>>({ name: '', phone: '', email: '' });
  const { user } = useAuth();
  const clientService = ClientService.getInstance();

  const loadClients = useCallback(async () => {
    if (user) {
      setLoading(true);
      try {
        const fetchedClients = await clientService.getClientsByUserId(user.uid);
        setClients(fetchedClients);
      } catch (error) {
        console.error('Error loading clients:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [user, clientService]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const handleCreateClient = async () => {
    if (user) {
      try {
        const createdClient = await clientService.createClient(user.uid, newClient);
        setClients([...clients, createdClient]);
        onClientSelect(createdClient);
        setOpen(false);
        setNewClient({ name: '', phone: '', email: '' });
      } catch (error) {
        console.error('Error creating client:', error);
      }
    }
  };

  return (
    <>
      <Autocomplete
        options={clients}
        getOptionLabel={(option) => `${option.name} (${option.phone})`}
        renderInput={(params) => <TextField {...params} label="Выберите клиента" />}
        value={selectedClient}
        onChange={(_, newValue) => onClientSelect(newValue)}
        loading={loading}
        loadingText="Загрузка клиентов..."
      />
      <Button onClick={() => setOpen(true)}>Добавить нового клиента</Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Добавить нового клиента</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Имя"
            type="text"
            fullWidth
            value={newClient.name}
            onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Телефон"
            type="tel"
            fullWidth
            value={newClient.phone}
            onChange={(e) => setNewClient({ ...newClient, phone: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={newClient.email}
            onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Отмена</Button>
          <Button onClick={handleCreateClient}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientSelector;
