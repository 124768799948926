import React, { useState } from 'react';
import { Typography, Box, Button, Divider, TextField, CircularProgress, IconButton, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { SelectedSlot, Client } from 'shared-lib';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import ClientSelector from '../ClientSelector';

interface ConfirmationStepProps {
  selectedSlot: SelectedSlot;
  onConfirm: (comment: string, client: Client | null) => Promise<void>;
  onClose: () => void;
}

const ConfirmationStep: React.FC<ConfirmationStepProps> = ({ selectedSlot, onConfirm, onClose }) => {
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [bookWithoutClient, setBookWithoutClient] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  
  const formatPrice = (price: number) => `${(price / 100).toFixed(0)} ₽`;
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { weekday: 'long', day: 'numeric', month: 'long' });
  };

  const calculateDuration = (start: string, end: string) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    const durationInMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}ч ${minutes > 0 ? minutes + 'мин' : ''}`;
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleConfirm = async () => {
    if (!selectedClient && !bookWithoutClient) {
      setOpenConfirmDialog(true);
      return;
    }
    await proceedWithBooking();
  };

  const proceedWithBooking = async () => {
    setIsLoading(true);
    try {
      await onConfirm(comment, bookWithoutClient ? null : selectedClient);
      onClose(); // Close the confirmation after successful booking
    } catch (error) {
      console.error('Booking failed:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Подтверждение бронирования
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Дата: {formatDate(selectedSlot.date)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Время: {selectedSlot.spot.start} - {selectedSlot.spot.end}, {calculateDuration(selectedSlot.spot.start, selectedSlot.spot.end)}
          </Typography>
        </Box>
      </Box>
      {!bookWithoutClient && (
        <ClientSelector
          onClientSelect={setSelectedClient}
          selectedClient={selectedClient}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={bookWithoutClient}
            onChange={(e) => {
              setBookWithoutClient(e.target.checked);
              if (e.target.checked) {
                setSelectedClient(null);
              }
            }}
          />
        }
        label="Бронировать без указания клиента"
      />
      <TextField
        fullWidth
        label="Комментарий"
        multiline
        rows={4}
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        margin="normal"
      />
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body1">Всего</Typography>
        <Typography variant="body1" fontWeight="bold">
          {formatPrice(selectedSlot.spot.prices)}
        </Typography>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button 
          onClick={handleConfirm}
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Онлайн-запись'
          )}
        </Button>
      </Box>
      <Typography variant="caption" sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
        Записываясь, вы даете согласие на обработку своих персональных данных.
      </Typography>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Подтверждение бронирования</DialogTitle>
        <DialogContent>
          <Typography>
            Вы не выбрали клиента. Вы уверены, что хотите продолжить бронирование без указания клиента?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Отмена</Button>
          <Button onClick={() => {
            setOpenConfirmDialog(false);
            setBookWithoutClient(true);
            proceedWithBooking();
          }} color="primary">
            Продолжить без клиента
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmationStep;
