import React from 'react';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home as HomeIcon, EventNote as EventNoteIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

const BottomAppBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
      >
        <BottomNavigationAction label="Бронирование" icon={<HomeIcon />} value="/" />
        <BottomNavigationAction label="Мои записи" icon={<EventNoteIcon />} value="/bookings" />
        <BottomNavigationAction label="Настройки" icon={<SettingsIcon />} value="/settings" />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomAppBar;