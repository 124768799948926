import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';
import { Booking, Office } from 'shared-lib';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';

interface BookingItemProps {
  booking: Booking;
  offices: Office[];
  onClick: () => void;
  isPast?: boolean;
  isToday?: boolean;
}

const BookingItem: React.FC<BookingItemProps> = ({ booking, offices, onClick, isPast, isToday }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const weekday = date.toLocaleDateString('ru-RU', { weekday: 'short' });
    const day = date.getDate();
    const month = date.toLocaleDateString('ru-RU', { month: 'short' });
    const time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    return `${weekday}, ${day} ${month} ${time}`;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'NEW': return 'success';
      case 'CANCELLED': return 'error';
      default: return 'default';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'NEW': return 'Подтверждено';
      case 'CANCELLED': return 'Отменено';
      default: return status;
    }
  };

  const getOfficeName = (officeId: string) => {
    const office = offices.find(o => o.id === officeId);
    return office ? office.name : 'Неизвестный офис';
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 2, 
        mb: 2, 
        cursor: 'pointer',
        backgroundColor: isToday ? 'rgba(255, 255, 0, 0.1)' : isPast ? 'rgba(0, 0, 0, 0.05)' : 'white'
      }} 
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocationOnIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="subtitle1">{getOfficeName(booking.officeId)}</Typography>
        </Box>
        <Chip 
          label={getStatusText(booking.status)} 
          color={getStatusColor(booking.status) as "success" | "error" | "default"}
          size="small"
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <EventIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">{formatDate(booking.reserved_on)}</Typography>
        </Box>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {booking.totalPrice ? formatPrice(booking.totalPrice) : 'Не указана'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PersonIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
        <Typography variant="body2">{booking.clientName}</Typography>
      </Box>
    </Paper>
  );
};

export default BookingItem;
