import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, Grid, Paper, useTheme, Divider, Button } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Office, Day, Spot, SelectedSlot } from 'shared-lib';
import { OfficeService } from 'shared-lib';

interface SlotSelectionProps {
  office: Office;
  onSelectSlot: (slot: SelectedSlot) => void;
}

const SlotSelection: React.FC<SlotSelectionProps> = ({ office, onSelectSlot }) => {
  const [days, setDays] = useState<Day[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | null>(null);
  const theme = useTheme();

  const officeService = OfficeService.getInstance();
  const isInitialMount = useRef(true);

  const fetchAvailableSlots = useCallback(async (isInitial = true) => {
    if (isLoading) return;
    setIsLoading(true);
    const startDate = isInitial ? new Date() : new Date(endDate.getTime() + 1 * 24 * 60 * 60 * 1000);
    const newEndDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    
    try {
      const newDays = await officeService.fetchAvailableSlots(office, startDate, newEndDate);
      if (isInitial) {
        setDays(newDays);
      } else {
        setDays(prevDays => [...prevDays, ...newDays]);
      }
      setEndDate(newEndDate);
      setHasMore(newDays.length > 0);
    } catch (error) {
      console.error("Ошибка при получении доступных слотов:", error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [office, endDate, isLoading, officeService]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchAvailableSlots();
    }
  }, [fetchAvailableSlots]);

  const loadMoreSlots = () => {
    if (hasMore && !isLoading) {
      fetchAvailableSlots(false);
    }
  };

  const handleSlotSelect = (date: string, spot: Spot) => {
    setSelectedSlot({ date, spot });
  };

  const handleConfirmSlot = () => {
    if (selectedSlot) {
      onSelectSlot(selectedSlot);
    }
  };

  const handleRemoveSlot = () => {
    setSelectedSlot(null);
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { weekday: 'long', day: 'numeric', month: 'long' });
  };

  const renderFloatingPanel = () => {
    if (!selectedSlot) return null;

    return (
      <Paper
        elevation={3}
        sx={{
          p: 2,          
          position: 'sticky',
          bottom: 16,
          left: 16,
          right: 16,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(5px)',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography variant="subtitle1" gutterBottom>Выбранный слот:</Typography>
        <Typography variant="body2">
          Дата: {formatDate(selectedSlot.date)}<br />
          Время: {selectedSlot.spot.start} - {selectedSlot.spot.end}<br />
          Цена: {formatPrice(selectedSlot.spot.prices)}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="secondary" onClick={handleRemoveSlot} sx={{ mr: 1 }}>
            Удалить
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirmSlot}>
            Продолжить
          </Button>
        </Box>
      </Paper>
    );
  };

  const renderSlot = (day: Day, spot: Spot, spotIndex: number) => {
    const isSelected = selectedSlot && selectedSlot.date === day.date && selectedSlot.spot.start === spot.start;
    const isAvailable = spot.quantity > 0;

    return (
      <Grid item xs={6} sm={4} md={3} key={`${day.date}-${spotIndex}`}>
        <Box sx={{ textAlign: 'center' }}>
          <Paper
            elevation={2}
            sx={{
              p: 1,
              cursor: isAvailable ? 'pointer' : 'not-allowed',
              backgroundColor: isSelected 
                ? theme.palette.primary.light 
                : isAvailable ? 'white' : theme.palette.action.disabledBackground,
              color: isSelected 
                ? theme.palette.primary.contrastText 
                : isAvailable ? 'inherit' : theme.palette.text.disabled,
              border: isSelected 
                ? `2px ${theme.palette.primary.main}` 
                : 'none',
              '&:hover': {
                backgroundColor: isAvailable
                  ? (isSelected ? theme.palette.primary.light : theme.palette.action.hover)
                  : theme.palette.action.disabledBackground,
              },
              transition: 'all 0.3s',
            }}
            onClick={() => isAvailable && handleSlotSelect(day.date, spot)}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{`${spot.start}—${spot.end}`}</Typography>
          </Paper>
          {isAvailable && (
            <Typography variant="caption" sx={{ mt: 0.5, fontWeight: 'bold', color: theme.palette.text.secondary }}>
              {formatPrice(spot.prices)}
            </Typography>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          height: selectedSlot ? 'calc(100% - 120px)' : '100%',
        }}
        id="scrollableDiv"
      >
        <InfiniteScroll
          dataLength={days.length}
          next={loadMoreSlots}
          hasMore={hasMore}
          loader={<Typography align="center" sx={{ my: 2 }}>Загрузка...</Typography>}
          endMessage={<Typography align="center" sx={{ my: 2 }}>Больше нет доступных слотов</Typography>}
          scrollableTarget="scrollableDiv"
        >
            <Box sx={{ p: 2 }}>
          {days.map((day, index) => (
            <Box key={day.date} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {formatDate(day.date)}
              </Typography>
              <Grid container spacing={2}>
                {day.spots.map((spot, spotIndex) => renderSlot(day, spot, spotIndex))}
              </Grid>
              {index < days.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
          </Box>
        </InfiniteScroll>
      </Box>
      {renderFloatingPanel()}
    </Box>
  );
};

export default SlotSelection;