import React, { useCallback } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { Box } from '@mui/material';
import { formatDateToString } from '../utils/dateUtils';

interface BookingCalendarProps {
  availableSlots: any[];
  onDateSelect: (date: Date) => void;
  selectedDate: Date | null;
  isMobile: boolean;
  onMonthChange: (date: Date) => void;
  currentMonth: Date;
}

const BookingCalendar: React.FC<BookingCalendarProps> = React.memo(({
  availableSlots,
  onDateSelect,
  selectedDate,
  isMobile,
  onMonthChange,
  currentMonth
}) => {
  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      onDateSelect(date);
    }
  }, [onDateSelect]);

  const handleMonthChange = useCallback((date: Date) => {
    onMonthChange(date);
  }, [onMonthChange]);

  const shouldDisableDate = useCallback((date: Date) => {
    const dateString = formatDateToString(date);
    return !availableSlots.some(slot => slot.date === dateString);
  }, [availableSlots]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Box sx={{ width: '100%' }}>
        <DateCalendar
          value={selectedDate}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          disablePast
          shouldDisableDate={shouldDisableDate}
          sx={isMobile ? {
            '& .MuiPickersCalendarHeader-label': {
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
            '& .MuiPickersDay-root': {
              fontSize: '1rem',
            },
          } : {}}
          defaultValue={currentMonth}
        />
      </Box>
    </LocalizationProvider>
  );
});

export default BookingCalendar;
