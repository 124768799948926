import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import TimeSlotSelector from './components/TimeSlotSelector/TimeSlotSelector';
import BookingsList from './components/BookingsList';
import Home from './components/Home';
import { Office } from 'shared-lib';
import Login from './components/Login';
import Profile from './components/Profile';
import { useAuth } from 'shared-lib';
import { OfficeService } from 'shared-lib';
import BookingDetails from './components/BookingDetails';
import Settings from './components/Settings';
import ServiceList from './components/ServiceList';
import ServiceEdit from './components/ServiceEdit';

const AppContent: React.FC = () => {
  const { user, loading } = useAuth();
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);
  const [isTimeSlotSelectorOpen, setIsTimeSlotSelectorOpen] = useState(false);
  const [offices, setOffices] = useState<Office[]>([]);

  const officeService = OfficeService.getInstance();

  const fetchOffices = useCallback(async () => {
    try {
      const fetchedOffices = await officeService.fetchOffices();
      setOffices(fetchedOffices);
    } catch (error) {
      console.error("Error fetching offices:", error);
    }
  }, [officeService]);

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);

  const handleSelectOffice = (office: Office) => {
    setSelectedOffice(office);
    setIsTimeSlotSelectorOpen(true);
  };

  const handleSelectSlot = (date: string, startTime: string, endTime: string, price: number) => {
    console.log(`Selected slot: ${date} ${startTime}-${endTime}, Price: ${price}`);
    setIsTimeSlotSelectorOpen(false);
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={
          <Home 
            offices={offices} 
            onSelectOffice={handleSelectOffice}
          />
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/bookings" element={
          <ProtectedRoute>
            <BookingsList />
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />
        <Route path="/bookings/:bookingId" element={<BookingDetails />} />
        <Route path="/settings" element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/settings/services" element={
          <ProtectedRoute>
            <ServiceList />
          </ProtectedRoute>
        } />
        <Route path="/settings/services/:serviceId" element={
          <ProtectedRoute>
            <ServiceEdit />
          </ProtectedRoute>
        } />
        <Route path="/settings/services/new" element={
          <ProtectedRoute>
            <ServiceEdit />
          </ProtectedRoute>
        } />
      </Routes>
      {selectedOffice && (
        <TimeSlotSelector
          office={selectedOffice}
          onSelectSlot={handleSelectSlot}
          open={isTimeSlotSelectorOpen}
          onClose={() => setIsTimeSlotSelectorOpen(false)}
          user={user}
        />
      )}
    </>
  );
};

export default AppContent;
