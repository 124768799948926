import { getFirestore, collection, getDocs, getDoc, doc, addDoc } from 'firebase/firestore';
import { Client } from 'shared-lib';

export class ClientService {
  private static instance: ClientService;
  private db = getFirestore();

  private constructor() {}

  public static getInstance(): ClientService {
    if (!ClientService.instance) {
      ClientService.instance = new ClientService();
    }
    return ClientService.instance;
  }

  async getClientsByUserId(userId: string): Promise<Client[]> {
    const clientsRef = collection(this.db, 'users', userId, 'clients');
    const clientsSnapshot = await getDocs(clientsRef);
    return clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Client));
  }

  async createClient(uid: string, client: Omit<Client, 'id' | 'uid'>): Promise<Client> {
    const clientsRef = collection(this.db, 'users', uid, 'clients');
    const clientDoc = await addDoc(clientsRef, {
      ...client,
      uid
    });
    return { id: clientDoc.id, ...client, uid };
  }

  async getClientById(userId: string, clientId: string): Promise<Client | null> {
    const clientDocRef = doc(this.db, 'users', userId, 'clients', clientId);
    const clientDoc = await getDoc(clientDocRef);
    if (!clientDoc.exists()) return null;
    return { id: clientDoc.id, ...clientDoc.data() } as Client;
  }
}
