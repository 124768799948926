import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Button, Typography, Box, Chip, 
  Card, CardContent, Grid, IconButton, Container
} from '@mui/material';
import { useAuth, UserService, ServiceData, OfficeService, Office } from 'shared-lib';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Обновляем интерфейс ServiceData (это должно быть сделано в shared-lib)
interface UpdatedServiceData extends Omit<ServiceData, 'schedule'> {
  id?: string;
  name: string;
  description: string;
  duration: number;
  price: number;
  officeId: string | null;
  userId: string;
  activeDays: string[];
}

// Обновляем интерфейс Office (это должно быть сделано в shared-lib)
interface UpdatedOffice extends Office {
  address?: string;
}

const ServiceList: React.FC = () => {
  const [services, setServices] = useState<UpdatedServiceData[]>([]);
  const [offices, setOffices] = useState<Record<string, UpdatedOffice>>({});
  const { user } = useAuth();
  const userService = UserService.getInstance();
  const officeService = OfficeService.getInstance();
  const navigate = useNavigate();

  const fetchServices = useCallback(async () => {
    if (user) {
      const userServices = await userService.getUserServices(user.uid);
      console.log('Fetched services:', JSON.stringify(userServices, null, 2)); // Отладочное сообщение
      setServices(userServices.map(service => ({
        ...service,
        activeDays: service.schedule ? Object.entries(service.schedule)
          .reduce((acc: string[], [key, value]) => {
            if (value.isActive && value.intervals.length > 0) {
              acc.push(key.toLowerCase());
            }
            return acc;
          }, []) : []
      })));
    }
  }, [user, userService]);

  const fetchOffices = useCallback(async () => {
    const fetchedOffices = await officeService.fetchOffices();
    const officesMap = fetchedOffices.reduce((acc, office) => {
      acc[office.id] = office;
      return acc;
    }, {} as Record<string, UpdatedOffice>);
    setOffices(officesMap);
  }, [officeService]);

  useEffect(() => {
    fetchServices();
    fetchOffices();
  }, [fetchServices, fetchOffices]);

  const getShortSchedule = (activeDays: string[]): string[] => {
    const daysShort = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
    const daysLong = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    
    return activeDays.map(day => {
      const dayIndex = daysLong.indexOf(day.toLowerCase());
      return dayIndex !== -1 ? daysShort[dayIndex] : day;
    });
  };

  const handleEditService = (serviceId: string | null) => {
    navigate(serviceId ? `/settings/services/${serviceId}` : '/settings/services/new');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button variant="contained" onClick={() => handleEditService('new')}>Создать новую услугу</Button>
      </Box>
      <Grid container spacing={3}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <Card 
              elevation={3} 
              sx={{ 
                position: 'relative', 
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
              onClick={() => handleEditService(service.id || null)}
            >
              <IconButton 
                sx={{ 
                  position: 'absolute', 
                  top: 8, 
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditService(service.id || null);
                }}
              >
                <EditIcon />
              </IconButton>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {service.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {service.description}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">{service.duration} мин</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AttachMoneyIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">{service.price} ₽</Typography>
                </Box>
                {service.officeId && offices[service.officeId] && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocationOnIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      {offices[service.officeId].address || 'Адрес не указан'}
                    </Typography>
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  {getShortSchedule(service.activeDays).map((day) => (
                    <Chip key={day} label={day} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServiceList;
