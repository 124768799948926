import React from 'react';
import { Office } from 'shared-lib';
import OfficeList from './OfficeList';

interface HomeProps {
  offices: Office[];
  onSelectOffice: (office: Office) => void;
}

const Home: React.FC<HomeProps> = ({ offices, onSelectOffice }) => {
  return (
    <div>
      <OfficeList offices={offices} onSelectOffice={onSelectOffice} />
    </div>
  );
};

export default Home;
