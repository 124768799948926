import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';

const Layout: React.FC = () => {
  const location = useLocation();

  const getPageInfo = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/':
        return { title: 'Бронирование', showBackButton: false };
      case '/bookings':
        return { title: 'Мои записи', showBackButton: false };
      case '/settings':
        return { title: 'Настройки', showBackButton: false };
      case '/profile':
        return { title: 'Профиль', showBackButton: true, backTo: '/' };
      case '/settings/services':
        return { title: 'Управление услугами', showBackButton: true, backTo: '/settings' };
      default:
        if (pathname.startsWith('/bookings/')) {
          return { title: 'Детали бронирования', showBackButton: true, backTo: '/bookings' };
        }
        if (pathname.startsWith('/book/')) {
          return { title: 'Бронирование', showBackButton: false };
        }
        if (pathname.startsWith('/settings/services/')) {
          return { 
            title: pathname.includes('/new') ? 'Создание услуги' : 'Редактирование услуги', 
            showBackButton: true, 
            backTo: '/settings/services' 
          };
        }
        return { title: 'Бронирование', showBackButton: false };
    }
  };

  return (
    <>
      <AppBar getPageInfo={getPageInfo} />
      <Box sx={{ pb: 7 }}>
        <Outlet />
      </Box>
      <BottomAppBar />
    </>
  );
};

export default Layout;
