import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@mui/material';
const GoogleCalendarError = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    const handleClose = () => {
        navigate('/offices');
    };
    return (_jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' } }, { children: _jsxs(Paper, Object.assign({ elevation: 3, sx: { p: 4, maxWidth: 600 } }, { children: [_jsx(Typography, Object.assign({ variant: "h5", gutterBottom: true }, { children: "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F Google Calendar" })), _jsx(Typography, Object.assign({ variant: "body1", gutterBottom: true }, { children: error || 'Произошла ошибка при подключении Google Calendar.' })), errorDescription && (_jsxs(Typography, Object.assign({ variant: "body2", color: "text.secondary", sx: { mt: 2, mb: 2 } }, { children: ["\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043E\u0448\u0438\u0431\u043A\u0438: ", errorDescription] }))), _jsx(Button, Object.assign({ variant: "contained", onClick: handleClose, sx: { mt: 2 } }, { children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" }))] })) })));
};
export default GoogleCalendarError;
