import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';

interface Pass {
  password?: string;
  passwordId?: string;
  status?: string;
  attempts?: number;
}

interface AccessCodeStatusProps {
  pass: Pass;
  bookingCreatedAt: Date;
}

const AccessCodeStatus: React.FC<AccessCodeStatusProps> = ({ pass, bookingCreatedAt }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getStatusMessage = () => {
    if (pass.status === 'ACTIVE' && pass.password) {
      return `Ваш код доступа: ${pass.password}`;
    }

    if (pass.status === 'FAILED') {
      return 'Не удалось установить код. Пожалуйста, свяжитесь с администратором.';
    }

    const messages = [
      'Устанавливаем код доступа...',
      'Пытаемся достучаться до замка...',
      'Меняем маршрут к замку...',
      'Звоним провайдеру...',
      'Ждем, пока администратор вручную установит код...',
      'Отправляем голубя с кодом...',
      'Пробуем телепатически передать код замку...',
    ];

    const index = Math.min(Math.floor(elapsedTime / 10), messages.length - 1);
    return messages[index];
  };

  const isLoading = !pass.status || pass.status === 'PENDING';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {isLoading && <CircularProgress size={20} sx={{ mr: 2 }} />}
      <Typography>{getStatusMessage()}</Typography>
    </Box>
  );
};

export default AccessCodeStatus;
