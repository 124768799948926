import React from 'react';
import { GoogleCalendarConnector, SettingsContainer, SettingsNavigationItem } from 'shared-lib';

const Settings: React.FC = () => {
  return (
    <SettingsContainer title="Настройки">
      <SettingsNavigationItem
        to="/settings/services"
        primary="Услуги"
        secondary="Управление услугами, которые вы предоставляете с привязкой к помещениям"
      />
      <GoogleCalendarConnector
        description="Подключенный аккаунт Google Calendar будет использоваться для учета вашей доступности и добавления бронирований в ваш календарь."
        userType='renter'
      />
    </SettingsContainer>
  );
};

export default Settings;
