import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, CircularProgress, Button } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { auth } from 'shared-lib';
import { Booking, Office } from 'shared-lib';
import { BookingService } from '../services/BookingService';
import { OfficeService } from 'shared-lib';
import BookingItem from './BookingItem';
import { useNavigate } from 'react-router-dom';

const BookingsList: React.FC = () => {
  const navigate = useNavigate();

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [showPastBookings, setShowPastBookings] = useState(false);
  const [pastBookings, setPastBookings] = useState<Booking[]>([]);

  const bookingService = BookingService.getInstance();
  const officeService = OfficeService.getInstance();

  const fetchBookings = useCallback(async () => {
    if (!auth.currentUser) return;

    try {
      const lastBooking = bookings.length > 0 ? bookings[bookings.length - 1] : null;
      const fetchedBookings = await bookingService.fetchUserBookings(auth.currentUser.uid, lastBooking);
      console.log('Fetched bookings:', fetchedBookings);
      
      if (fetchedBookings.length === 0) {
        setHasMore(false);
      } else {
        setBookings(prevBookings => {
          const newBookings = [...prevBookings, ...fetchedBookings];
          const uniqueBookings = newBookings.filter((booking, index, self) =>
            index === self.findIndex((t) => t.id === booking.id)
          );
          return uniqueBookings;
        });
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setError('Ошибка при загрузке бронирований');
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [bookings, bookingService]);

  useEffect(() => {
    if (!auth.currentUser) {
      setError('Пользователь не авторизован');
      setIsLoading(false);
      return;
    }

    const fetchOffices = async () => {
      try {
        const fetchedOffices = await officeService.fetchOffices();
        console.log('Fetched offices:', fetchedOffices);
        setOffices(fetchedOffices);
      } catch (error) {
        console.error("Error fetching offices:", error);
      }
    };

    fetchOffices();
    fetchBookings();
  }, [fetchBookings, officeService]);

  const fetchPastBookings = async () => {
    if (!auth.currentUser) return;

    try {
      const fetchedPastBookings = await bookingService.fetchPastUserBookings(auth.currentUser.uid);
      console.log('Fetched past bookings:', fetchedPastBookings);
      setPastBookings(fetchedPastBookings);
      setShowPastBookings(true);
    } catch (error) {
      console.error("Error fetching past bookings:", error);
    }
  };

  const now = new Date();
  const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

  const todayBookings = bookings.filter(booking => new Date(booking.reserved_on) <= todayEnd);

  const handleBookingClick = (bookingId: string) => {
    navigate(`/bookings/${bookingId}`);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 600, margin: '0 auto' }}>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          {!showPastBookings && (
            <Button onClick={fetchPastBookings} sx={{ mb: 2 }}>
              Показать прошлые бронирования
            </Button>
          )}
          {showPastBookings && pastBookings.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>Прошлые бронирования</Typography>
              {pastBookings.map((booking) => (
                <BookingItem key={booking.id} booking={booking} offices={offices} isPast={true} onClick={() => handleBookingClick(booking.id)} />
              ))}
            </Box>
          )}
          {todayBookings.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>Сегодняшние бронирования</Typography>
              {todayBookings.map((booking) => (
                <BookingItem key={booking.id} booking={booking} offices={offices} isToday={true} onClick={() => handleBookingClick(booking.id)} />
              ))}
            </Box>
          )}
          <Typography variant="h6" gutterBottom>Предстоящие бронирования</Typography>
          <InfiniteScroll
            dataLength={bookings.length}
            next={fetchBookings}
            hasMore={hasMore}
            loader={<CircularProgress />}
            endMessage={<Typography align="center">Больше бронирований нет</Typography>}
          >
            {bookings.map((booking) => (
              <BookingItem 
                key={booking.id} 
                booking={booking} 
                offices={offices} 
                onClick={() => handleBookingClick(booking.id)}
              />
            ))}
          </InfiniteScroll>
          {bookings.length === 0 && !hasMore && (
            <Typography align="center">Нет предстоящих бронирований</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default BookingsList;
