var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
const GoogleCalendarCallback = () => {
    const location = useLocation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const functions = getFunctions(undefined, 'europe-west3');
    useEffect(() => {
        const handleCallback = () => __awaiter(void 0, void 0, void 0, function* () {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            const state = searchParams.get('state');
            const error = searchParams.get('error');
            const errorDescription = searchParams.get('error_description');
            if (error) {
                console.error('Google OAuth error:', error, errorDescription);
                setError(`Ошибка Google OAuth: ${error}${errorDescription ? ` - ${errorDescription}` : ''}`);
                setLoading(false);
                return;
            }
            if (code && state) {
                try {
                    const handleGoogleAuthCallback = httpsCallable(functions, 'handleGoogleAuthCallback');
                    const result = yield handleGoogleAuthCallback({ code, state, error, error_description: errorDescription });
                    const data = result.data;
                    if (data.success) {
                        // Send a message to the opener window
                        window.opener.postMessage({ type: 'GOOGLE_CALENDAR_CONNECTED' }, '*');
                        // Close the window automatically
                        window.close();
                    }
                    else {
                        throw new Error('Неизвестная ошибка при подключении Google Calendar');
                    }
                }
                catch (error) {
                    console.error('Error:', error);
                    setError('Не удалось подключить Google Calendar');
                }
            }
            else {
                setError('Недопустимые параметры обратного вызова');
            }
            setLoading(false);
        });
        handleCallback();
    }, [location, functions]);
    if (loading) {
        return _jsx(CircularProgress, {});
    }
    if (error) {
        return _jsx(Typography, Object.assign({ color: "error" }, { children: error }));
    }
    return _jsx(Typography, { children: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435 Google Calendar \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043E. \u042D\u0442\u043E \u043E\u043A\u043D\u043E \u043C\u043E\u0436\u043D\u043E \u0437\u0430\u043A\u0440\u044B\u0442\u044C." });
};
export default GoogleCalendarCallback;
