import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import { Office, OfficeItem } from 'shared-lib';

interface OfficeListProps {
  offices: Office[];
  onSelectOffice: (office: Office) => void;
}

const OfficeList: React.FC<OfficeListProps> = ({ offices, onSelectOffice }) => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Доступные офисы
      </Typography>
      <Grid container spacing={3}>
        {offices.map(office => (
          <Grid item xs={12} sm={6} md={4} key={office.id}>
            <OfficeItem office={office} onSelect={onSelectOffice} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OfficeList;
