import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useUnsavedChangesWarning = (
  getMessage: () => string | null
) => {
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = getMessage();
      if (message) {
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    const handleLocationChange = () => {
      const message = getMessage();
      if (message && !window.confirm(message)) {
        window.history.pushState(null, '', location.pathname);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [getMessage, location]);
};