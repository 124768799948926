import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Typography, Box, Button, Paper, 
  IconButton, CircularProgress, Snackbar, Fab, Container,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Accordion, AccordionSummary, AccordionDetails,
  Tooltip, Card, CardContent, TextField, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAuth, UserService, OfficeService, ServiceData, DateOverride, Office } from 'shared-lib';
import { useUnsavedChangesWarning } from '../hooks/useUnsavedChangesWarning';
import { ru } from 'date-fns/locale';
import { Schedule } from 'shared-lib';
import { ScheduleEdit } from 'shared-lib';
import { DateOverridesEdit } from 'shared-lib';
import { SelectChangeEvent } from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';

// Обновляем тип ServiceData (это должно быть сделано в shared-lib, но для демонстрации мы сделаем это здесь)
interface UpdatedServiceData extends Omit<ServiceData, 'officeId'> {
  officeId: string;
}

const ServiceEdit: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const [service, setService] = useState<UpdatedServiceData>({
    name: '',
    description: '',
    duration: 60,
    price: 0,
    officeId: '',
    userId: ''
  });
  const [dateOverrides, setDateOverrides] = useState<DateOverride[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [deletedDays, setDeletedDays] = useState<string[]>([]);
  const [copySuccess, setCopySuccess] = useState('');
  const [schedule, setSchedule] = useState<Schedule>({});
  const [offices, setOffices] = useState<Office[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { user } = useAuth();
  const userService = UserService.getInstance();
  const officeService = OfficeService.getInstance();

  const fetchOffices = useCallback(async () => {
    if (!user) return;
    try {
      const fetchedOffices = await officeService.fetchOffices();
      setOffices(fetchedOffices);
    } catch (error) {
      console.error('Error fetching offices:', error);
      setError('Failed to load offices');
    }
  }, [user, officeService]);

  const fetchServiceData = useCallback(async () => {
    if (!user || !serviceId) return;
    
    try {
      setLoading(true);
      const serviceData = await userService.getServiceById(user.uid, serviceId);
      console.log('Fetched service data:', serviceData); // Для отладки
      setService({
        ...serviceData,
        officeId: serviceData.officeId || ''
      });
      const fetchedDateOverrides = await userService.getServiceDateOverrides(user.uid, serviceId);
      setDateOverrides(fetchedDateOverrides);
      const fetchedSchedule = await userService.getUserSchedule(user.uid, serviceId);
      setSchedule(fetchedSchedule);
    } catch (error) {
      console.error('Error fetching service data:', error);
      setError('Failed to load service data');
    } finally {
      setLoading(false);
    }
  }, [user, serviceId, userService]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        await fetchOffices();
        if (serviceId && serviceId !== 'new') {
          await fetchServiceData();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, serviceId, fetchOffices, fetchServiceData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setService(prev => ({ ...prev, [name]: value }));
    setIsChanged(true);
  };

  const handleOfficeChange = (event: SelectChangeEvent<string>) => {
    setService(prev => ({ ...prev, officeId: event.target.value }));
    setIsChanged(true);
  };

  const handleAddDate = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(null);
  };

  const handleConfirmDate = () => {
    if (selectedDate) {
      const dateString = selectedDate.toISOString().split('T')[0];
      if (!dateOverrides.find(override => override.id === dateString)) {
        const newOverride: DateOverride = { 
          id: dateString, 
          intervals: []
        };
        setDateOverrides(prev => [...prev, newOverride]);
        setIsChanged(true);
      }
    }
    handleCloseDialog();
  };

  const handleIntervalChange = (overrideIndex: number, intervalIndex: number, field: 'start' | 'end', value: string) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.map((interval, idx) => 
          idx === intervalIndex ? { ...interval, [field]: value } : interval
        )
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const addInterval = (overrideIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: [...newOverrides[overrideIndex].intervals, { start: '09:00', end: '18:00' }]
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeInterval = (overrideIndex: number, intervalIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.filter((_, idx) => idx !== intervalIndex)
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeDay = (overrideIndex: number) => {
    const dayToRemove = dateOverrides[overrideIndex];
    setDateOverrides(prev => prev.filter((_, idx) => idx !== overrideIndex));
    setDeletedDays(prev => [...prev, dayToRemove.id]);
    setIsChanged(true);
  };

  const handleSave = async () => {
    if (!user) return;
    
    if (!service.officeId) {
      setError('Пожалуйста, выберите офис');
      return;
    }
    
    try {
      setSaving(true);
      const serviceWithUserId = {
        ...service,
        userId: user.uid,
      };

      const savedService = await userService.saveService(user.uid, serviceWithUserId);
      
      const serviceId = savedService.id;

      if (serviceId) {
        await userService.saveServiceDateOverrides(user.uid, serviceId, dateOverrides);
      
        for (const date of deletedDays) {
          await userService.deleteServiceDateOverride(user.uid, serviceId, date);
        }

        await userService.saveUserSchedule(user.uid, serviceId, schedule);
      }
      
      setError('Сервис успешно сохранен');
      setIsChanged(false);
      setDeletedDays([]);
      
      if (!service.id) {
        setService(prev => ({ ...prev, id: serviceId }));
      }
    } catch (error) {
      console.error('Error saving service:', error);
      setError('Не удалось сохранить сервис. Пожалуйста, попробуйте еще раз.');
    } finally {
      setSaving(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useUnsavedChangesWarning(() => isChanged ? "You have unsaved changes. Are you sure you want to leave?" : null);

  const getServiceUrl = () => {
    return `${window.location.origin}/book/${user?.uid}/${service.id}`;
  };

  const copyServiceUrl = () => {
    const url = getServiceUrl();
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess('Ссылка скопирована!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Не удалось скопировать: ', err);
    });
  };

  const openServiceInNewTab = () => {
    const url = getServiceUrl();
    window.open(url, '_blank');
  };

  const handleDelete = async () => {
    if (!user || !service.id) return;
    
    try {
      setSaving(true);
      await userService.deleteService(user.uid, service.id);
      setError('Сервис успешно удален');
      setTimeout(() => {
        navigate('/settings/services');
      }, 2000);
    } catch (error) {
      console.error('Error deleting service:', error);
      setError('Не удалось удалить сервис. Пожалуйста, попробуйте еще раз.');
    } finally {
      setSaving(false);
      setDeleteDialogOpen(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Container sx={{ mt: 4 }}>
        {/* Удалены кнопка "Назад" и заголовок */}
        <Box sx={{ pb: 10 }}>
          {/* Основная информация о услуге */}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>Основная информация</Typography>
            <TextField
              fullWidth
              label="Название услуги"
              name="name"
              value={service.name}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Описание"
              name="description"
              value={service.description}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Длительность (минуты)"
              name="duration"
              type="number"
              value={service.duration}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Цена"
              name="price"
              type="number"
              value={service.price}
              onChange={handleInputChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="office-select-label">Офис *</InputLabel>
              <Select
                labelId="office-select-label"
                value={service.officeId}
                onChange={handleOfficeChange}
                label="Офис *"
                required
              >
                <MenuItem value="" disabled>
                  <em>Выберите офис</em>
                </MenuItem>
                {offices.map((office) => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>

          {service.id && (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Ссылка на услугу
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    {getServiceUrl()}
                  </Typography>
                  <Box>
                    <Tooltip title="Скопировать ссылку">
                      <IconButton onClick={copyServiceUrl} color="primary">
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Открыть в новой вкладке">
                      <IconButton onClick={openServiceInNewTab} color="primary">
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>График работы</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Укажите здесь стандартный еженедельный график работы вашей услуги. 
                Этот график будет применяться ко всем дням, если не указано иное 
                в разделе "Особый график для отдельных дат".
              </Typography>
              <ScheduleEdit 
                schedule={schedule} 
                onChange={(newSchedule) => {
                  setSchedule(newSchedule);
                  setIsChanged(true);
                }} 
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Особый график для отдельных дат</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Здесь вы можете задать особый график работы для определенных дат, 
                когда время работы отличается от стандартного еженедельного графика.
              </Typography>
              <DateOverridesEdit
                dateOverrides={dateOverrides}
                onAddDate={handleAddDate}
                onRemoveDay={removeDay}
                onIntervalChange={handleIntervalChange}
                onAddInterval={addInterval}
                onRemoveInterval={removeInterval}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        
        <Fab 
          color="primary" 
          size="small" 
          onClick={scrollToTop}
          sx={{ position: 'fixed', bottom: 80, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>

        <Paper 
          elevation={3} 
          sx={{ 
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            p: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            backgroundColor: 'background.paper',
            zIndex: 1100,
          }}
        >
          <Button 
            variant="contained" 
            onClick={handleSave} 
            disabled={saving || !isChanged || !service.officeId} // Добавлена проверка на наличие выбранного офиса
            startIcon={<SaveIcon />}
          >
            {saving ? 'Сохранение...' : 'Сохранить сервис'}
          </Button>
        </Paper>

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />

        <Snackbar
          open={!!copySuccess}
          autoHideDuration={2000}
          onClose={() => setCopySuccess('')}
          message={copySuccess}
        />

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Выберите дату</DialogTitle>
          <DialogContent>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Отмена</Button>
            <Button onClick={handleConfirmDate} disabled={!selectedDate}>
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>

        {service.id && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteDialogOpen(true)}
            sx={{ mt: 2 }}
          >
            Удалить услугу
          </Button>
        )}

        {/* Диалог подтверждения удаления */}
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Подтвердите удаление</DialogTitle>
          <DialogContent>
            <Typography>
              Вы уверены, что хотите удалить эту услугу? Это действие нельзя отменить.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Отмена</Button>
            <Button onClick={handleDelete} color="error">
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default ServiceEdit;
