import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppBar as MuiAppBar, Toolbar, Typography, Button, Box, IconButton, Avatar, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
const AppBar = ({ getPageInfo }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const getDisplayName = () => {
        var _a;
        if (user && user.displayName) {
            const names = user.displayName.split(' ');
            return `${names[0]} ${(_a = names[1]) === null || _a === void 0 ? void 0 : _a.charAt(0)}.`;
        }
        return 'Пользователь';
    };
    const pageInfo = getPageInfo();
    const handleBackClick = () => {
        if (pageInfo.backTo) {
            navigate(pageInfo.backTo);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(MuiAppBar, Object.assign({ position: "fixed" }, { children: _jsxs(Toolbar, { children: [pageInfo.showBackButton && (_jsx(IconButton, Object.assign({ edge: "start", color: "inherit", onClick: handleBackClick, sx: { mr: 2 } }, { children: _jsx(ArrowBackIcon, {}) }))), _jsx(Typography, Object.assign({ variant: "h6", component: "div", sx: { flexGrow: 1 } }, { children: pageInfo.title })), user ? (_jsx(Box, { children: isMobile ? (_jsx(IconButton, Object.assign({ component: Link, to: "/profile", sx: { color: 'inherit' } }, { children: _jsx(Avatar, Object.assign({ sx: { width: 32, height: 32, bgcolor: 'common.white', color: 'primary.main' } }, { children: _jsx(PersonIcon, {}) })) }))) : (_jsx(Typography, Object.assign({ variant: "body1", component: Link, to: "/profile", sx: {
                                    mr: 2,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }
                                } }, { children: getDisplayName() }))) })) : (_jsx(Button, Object.assign({ color: "inherit", component: Link, to: "/login" }, { children: "\u0412\u043E\u0439\u0442\u0438" })))] }) })), _jsx(Toolbar, {}), " "] }));
};
export default AppBar;
