import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Container, CircularProgress, Box, TextField, Button, Paper, Grid, useMediaQuery, useTheme, AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar, Divider, Stepper, Step, StepLabel } from '@mui/material';
import { OfficeService, Day, Spot, useAuth } from 'shared-lib';
import { BookingService } from '../services/BookingService';
import BookingCalendar from './BookingCalendar';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { PublicServiceInfoService } from '../services/PublicServiceInfoService';
import { PublicServiceInfo } from '../types/types';
import { formatDateToString } from '../utils/dateUtils';

interface ClientData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const BookingPage: React.FC = () => {
  const { userId = '', serviceId = '' } = useParams<{ userId: string; serviceId: string }>();
  const navigate = useNavigate();
  const [serviceInfo, setServiceInfo] = useState<PublicServiceInfo | null>(null);
  const [availableSlots, setAvailableSlots] = useState<Day[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [clientData, setClientData] = useState<ClientData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Spot | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<{
    date: string;
    time: string;
    serviceName: string;
    providerName: string;
  } | null>(null);
  const [isBooking, setIsBooking] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const publicServiceInfoService = useMemo(() => PublicServiceInfoService.getInstance(), []);
  const officeService = useMemo(() => OfficeService.getInstance(), []);
  const bookingService = useMemo(() => BookingService.getInstance(), []);

  const fetchAvailableSlots = useCallback(async (start: Date, end: Date) => {
    if (!serviceId || !userId) return;

    try {
      const slots = await officeService.fetchAvailableSlotsForUser(
        serviceId,
        userId,
        start,
        end
      );
      setAvailableSlots(prevSlots => {
        const filteredPrevSlots = prevSlots.filter(day => {
          const dayDate = new Date(day.date);
          return dayDate < start || dayDate > end;
        });
        return [...filteredPrevSlots, ...slots];
      });
    } catch (err) {
      console.error('Error fetching available slots:', err);
      setError('Failed to load available slots');
    }
  }, [serviceId, userId, officeService]);

  const fetchServiceInfo = useCallback(async () => {
    if (!userId || !serviceId) return;

    try {
      const fetchedServiceInfo = await publicServiceInfoService.getPublicServiceInfo(userId, serviceId);
      setServiceInfo(fetchedServiceInfo);
    } catch (err) {
      console.error('Error fetching service info:', err);
      setError('Failed to load service information');
    }
  }, [userId, serviceId, publicServiceInfoService]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      await Promise.all([fetchServiceInfo(), fetchAvailableSlots(new Date(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))]);
      setLoading(false);
    };

    fetchInitialData();
  }, [fetchServiceInfo, fetchAvailableSlots]);

  useEffect(() => {
    // Загрузка данных из локального хранилища при монтировании компонента
    const savedClientData = localStorage.getItem('clientData');
    if (savedClientData) {
      setClientData(JSON.parse(savedClientData));
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setClientData(prevData => {
      const newData = { ...prevData, [name]: value };
      // Сохранение данных в локальное хранилище при каждом изменении
      localStorage.setItem('clientData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleDateSelect = useCallback((date: Date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
    if (isMobile) {
      setActiveStep(1); // Автоматически переходим к выбору времени
    }
  }, [isMobile]);

  const handleSlotSelect = useCallback((slot: Spot) => {
    setSelectedSlot(slot);
  }, []);

  const handleNext = () => {
    if (isMobile) {
      if (activeStep === 1 && !selectedDate) {
        alert('Пожалуйста, выберите дату');
        return;
      }
      if (activeStep === 2 && !selectedSlot) {
        alert('Пожалуйста, выберите время');
        return;
      }
      if (activeStep === 3 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone)) {
        alert('Пожалуйста, заполните все поля');
        return;
      }
    } else {
      if (activeStep === 0 && (!selectedDate || !selectedSlot)) {
        alert('Пожалуйста, выберите дату и время');
        return;
      }
      if (activeStep === 1 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone)) {
        alert('Пожалуйста, заполните все поля');
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    if (!selectedDate || !selectedSlot || !serviceInfo || !serviceInfo.provider) {
      console.error("Не выбрана дата, время или отсутствует информация о сервисе или провайдере");
      return;
    }

    const selectedSlotData = {
      date: formatDateToString(selectedDate),
      spot: selectedSlot
    };

    setIsBooking(true);  // Начало процесса бронирования

    try {
      await bookingService.createBooking(
        serviceInfo.location?.id || '',
        selectedSlotData,
        userId,
        '',
        {
          name: `${clientData.firstName} ${clientData.lastName}`,
          phone: clientData.phone,
          email: clientData.email,
        },
        serviceId  // Добавляем serviceId здесь
      );
      setBookingSuccess(true);
      setBookingDetails({
        date: selectedDate.toLocaleDateString('ru-RU'),
        time: `${selectedSlot.start} - ${selectedSlot.end}`,
        serviceName: serviceInfo.name,
        providerName: `${serviceInfo.provider.firstName} ${serviceInfo.provider.lastName}`,
      });
    } catch (error) {
      console.error("Ошибка при создании бронирования:", error);
      setError('Не удалось создать бронирование. Пожалуйста, попробуйте еще раз.');
    } finally {
      setIsBooking(false);  // Завершение процесса бронирования
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    handleMenuClose();
  };

  const handleEditService = () => {
    if (serviceInfo) {
      navigate(`/edit-service/${serviceInfo.id}`);
    }
    handleMenuClose();
  };

  const handleMonthChange = useCallback((date: Date) => {
    if (date.getMonth() !== currentMonth.getMonth() || date.getFullYear() !== currentMonth.getFullYear()) {
      setCurrentMonth(date);
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      fetchAvailableSlots(startOfMonth, endOfMonth);
    }
  }, [currentMonth, fetchAvailableSlots]);

  const renderSpecialistInfo = () => (
    <Box mb={4}>
      {serviceInfo?.provider && (
        <>
          <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
            {serviceInfo.provider.firstName.charAt(0)}
            {serviceInfo.provider.lastName.charAt(0)}
          </Avatar>
          <Typography variant="h5" gutterBottom>
            {serviceInfo.provider.firstName} {serviceInfo.provider.lastName}
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {serviceInfo.name}
          </Typography>
          {serviceInfo.provider.email && (
            <Typography variant="body2">
              <EmailIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              {serviceInfo.provider.email}
            </Typography>
          )}
          {serviceInfo.provider.phone && (
            <Typography variant="body2">
              <PhoneIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              {serviceInfo.provider.phone}
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  const renderServiceDetails = () => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>О услуге</Typography>
      {serviceInfo?.description && (
        <Typography variant="body2" paragraph>{serviceInfo.description}</Typography>
      )}
      <Box mb={2}>
        <Typography variant="body2">
          <AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
          Длительность: {serviceInfo?.duration} мин.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">
          <AttachMoneyIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
          Стоимость: {serviceInfo?.price} руб.
        </Typography>
      </Box>
      {serviceInfo?.location && (
        <Box mb={2}>
          <Typography variant="body2">
            <LocationOnIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
            Адрес: {serviceInfo.location.address}
          </Typography>
          {serviceInfo.location.instructions && (
            <Typography variant="body2">
              Инструкции: {serviceInfo.location.instructions}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );

  const renderCalendar = () => (
    <BookingCalendar
      availableSlots={availableSlots}
      onDateSelect={handleDateSelect}
      selectedDate={selectedDate}
      isMobile={isMobile}
      onMonthChange={handleMonthChange}
      currentMonth={currentMonth}
    />
  );

  const renderTimeSlots = () => (
    selectedDate && (
      <Box>
        <Typography variant="h6" gutterBottom>Доступное время:</Typography>
        <Grid container spacing={1}>
          {availableSlots
            .find(day => day.date === formatDateToString(selectedDate))
            ?.spots.map((slot, index) => (
              <Grid item key={index}>
                <Button
                  size="small"
                  variant={selectedSlot === slot ? "contained" : "outlined"}
                  onClick={() => slot.quantity > 0 ? handleSlotSelect(slot) : null}
                  disabled={slot.quantity === 0}
                  sx={{
                    opacity: slot.quantity === 0 ? 0.5 : 1,
                    cursor: slot.quantity === 0 ? 'not-allowed' : 'pointer',
                  }}
                >
                  {slot.start}
                </Button>
              </Grid>
            ))}
        </Grid>
      </Box>
    )
  );

  const renderDesktopLayout = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        {renderSpecialistInfo()}
        {renderServiceDetails()}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" gutterBottom>Выберите дату</Typography>
        {renderCalendar()}
      </Grid>
      <Grid item xs={12} md={3}>
        {renderTimeSlots()}
      </Grid>
    </Grid>
  );

  const renderMobileLayout = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            {renderSpecialistInfo()}
            <Divider sx={{ my: 2 }} />
            {renderServiceDetails()}
            <Button
              variant="contained"
              fullWidth
              onClick={handleNext}
              sx={{ mt: 2 }}
            >
              Перейти к выбору даты
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h5" gutterBottom>Выберите дату</Typography>
            {renderCalendar()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!selectedDate}
              >
                Выбрать время
              </Button>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Выберите время для {selectedDate?.toLocaleDateString('ru-RU')}
            </Typography>
            {renderTimeSlots()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!selectedSlot}
              >
                Далее
              </Button>
            </Box>
          </>
        );
      case 3:
        return (
          <>
            {renderClientDataForm()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone}
              >
                Далее
              </Button>
            </Box>
          </>
        );
      case 4:
        return (
          <>
            {renderConfirmation()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Забронировать
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const renderClientDataForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Имя" name="firstName" value={clientData.firstName} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Фамилия" name="lastName" value={clientData.lastName} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Email" name="email" type="email" value={clientData.email} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Телефон" name="phone" value={clientData.phone} onChange={handleInputChange} />
      </Grid>
    </Grid>
  );

  const renderConfirmation = () => (
    <Box>
      <Typography variant="subtitle1" gutterBottom>Подтверждение бронирования</Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}><EventIcon fontSize="small" /> Дата:</Grid>
        <Grid item xs={6}>{selectedDate?.toLocaleDateString('ru-RU')}</Grid>
        <Grid item xs={6}><AccessTimeIcon fontSize="small" /> Время:</Grid>
        <Grid item xs={6}>{`${selectedSlot?.start} - ${selectedSlot?.end}`}</Grid>
        <Grid item xs={6}><PersonIcon fontSize="small" /> Имя:</Grid>
        <Grid item xs={6}>{`${clientData.firstName} ${clientData.lastName}`}</Grid>
        <Grid item xs={6}><EmailIcon fontSize="small" /> Email:</Grid>
        <Grid item xs={6}>{clientData.email}</Grid>
        <Grid item xs={6}><PhoneIcon fontSize="small" /> Телефон:</Grid>
        <Grid item xs={6}>{clientData.phone}</Grid>
      </Grid>
      {isBooking && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  const steps = isMobile 
    ? ['1', '2', '3', '4', '5']
    : ['Выбор даты и времени', 'Ввод данны', 'Подтверждение'];

  const renderStepContent = (step: number) => {
    if (isMobile) {
      return renderMobileLayout();
    } else {
      switch (step) {
        case 0:
          return renderDesktopLayout();
        case 1:
          return renderClientDataForm();
        case 2:
          return renderConfirmation();
        default:
          return null;
      }
    }
  };

  const renderNavigation = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button 
        disabled={activeStep === 0 || isBooking} 
        onClick={handleBack}
      >
        Назад
      </Button>
      <Button
        variant="contained"
        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
        disabled={
          isBooking ||
          (activeStep === 0 && (!selectedDate || !selectedSlot)) ||
          (activeStep === 1 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone))
        }
      >
        {activeStep === steps.length - 1 ? (isBooking ? 'Бронирование...' : 'Забронировать') : 'Далее'}
      </Button>
    </Box>
  );

  const renderSuccessMessage = () => (
    <Box textAlign="center">
      <Typography variant="h5" gutterBottom>
        Бронирование успешно создано!
      </Typography>
      <Typography variant="body1" paragraph>
        Вы забронировали {bookingDetails?.serviceName} 
        {bookingDetails?.providerName && ` у ${bookingDetails.providerName}`} 
        на {bookingDetails?.date} с {bookingDetails?.time}.
      </Typography>
      <Typography variant="body1" paragraph>
        {bookingDetails?.providerName && `Рекомендуем связаться с ${bookingDetails.providerName} для подтверждения деталей. `}
        Дальнейшее управление бронированием осуществляется через вашего специалиста.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          setBookingSuccess(false);
          setActiveStep(0);
          setSelectedDate(null);
          setSelectedSlot(null);
        }}
      >
        Создать новое бронирование
      </Button>
    </Box>
  );

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !userId || !serviceId || !serviceInfo) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Ошибка
        </Typography>
        <Typography>{error || 'Неверные параметры URL или услуга не найдена'}</Typography>
      </Container>
    );
  }

  if (bookingSuccess) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          {renderSuccessMessage()}
        </Paper>
      </Container>
    );
  }

  return (
    <>
      {user && (
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" component={Link} to="/">
              <HomeIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Бронирование
            </Typography>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditService}>
                <EditIcon fontSize="small" sx={{ mr: 1 }} />
                Редатировать сервис
              </MenuItem>
              <MenuItem onClick={handleCopyLink}>
                <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
                Копировать ссылку
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                {isMobile ? (
                  <StepLabel StepIconComponent={() => (
                    <Avatar sx={{ width: 24, height: 24, bgcolor: index <= activeStep ? 'primary.main' : 'grey.400', fontSize: '0.8rem' }}>
                      {label}
                    </Avatar>
                  )} />
                ) : (
                  <StepLabel>{label}</StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
          {renderStepContent(activeStep)}
          {!isMobile && renderNavigation()}
        </Paper>
      </Container>
    </>
  );
};

export default React.memo(BookingPage);
