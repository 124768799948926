import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Paper, Button, CircularProgress, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Booking, Office, OfficeService } from 'shared-lib';
import { BookingService } from '../services/BookingService';
import { ClientService } from '../services/ClientService';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CommentIcon from '@mui/icons-material/Comment';
import AccessCodeStatus from './AccessCodeStatus';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const BookingDetails: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const navigate = useNavigate();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [office, setOffice] = useState<Office | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [actionComment, setActionComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const [isCancelling, setIsCancelling] = useState(false);

  const bookingService = BookingService.getInstance();
  const officeService = OfficeService.getInstance();
  const clientService = ClientService.getInstance();

  useEffect(() => {
    if (!bookingId) return;

    setLoading(true);
    setError(null);

    const unsubscribe = bookingService.subscribeToBooking(bookingId, (fetchedBooking) => {
      setBooking(fetchedBooking);
      if (fetchedBooking.officeId) {
        officeService.getOfficeById(fetchedBooking.officeId)
          .then(fetchedOffice => {
            if (fetchedOffice) {
              setOffice(fetchedOffice);
            } else {
              console.warn(`Office with id ${fetchedBooking.officeId} not found`);
            }
          })
          .catch(err => {
            console.error("Error fetching office:", err);
            setError('Failed to fetch office details');
          });
      }
      setLoading(false);
    }, (err) => {
      console.error("Error fetching booking:", err);
      setError('Failed to fetch booking details');
      setLoading(false);
    });

    return () => unsubscribe();
  }, [bookingId, bookingService, officeService, clientService]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!booking) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Typography>Бронирование не найдено</Typography>
      </Box>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
  };

  const formatTimeInterval = (startDateString: string, durationMinutes: number) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate.getTime() + durationMinutes * 60000);
    return `${startDate.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })} - ${endDate.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'NEW':
        return 'success';
      case 'DRAFT':
        return 'warning';
      case 'CANCELLED':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'NEW':
        return 'Подтверждено';
      case 'DRAFT':
        return 'Черновик';
      case 'CANCELLED':
        return 'Отменено';
      default:
        return status;
    }
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  const handleCancel = async () => {
    if (!bookingId) {
      setError('Booking ID is missing');
      return;
    }

    if (!actionComment.trim()) {
      setCommentError('Пожалуйста, укажите причину отмены');
      return;
    }
    
    setIsCancelling(true);
    try {
      await bookingService.cancelBooking(bookingId, actionComment);
      // Обновляем состояние booking после отмены
      const updatedBooking = await bookingService.getBookingById(bookingId);
      setBooking(updatedBooking);
      setOpenCancelDialog(false);
      setActionComment('');
      setCommentError('');
    } catch (error) {
      console.error('Error cancelling booking:', error);
      setError('Failed to cancel booking. Please try again.');
    } finally {
      setIsCancelling(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4">{office?.name}</Typography>
          <Chip 
            label={getStatusText(booking.status)} 
            color={getStatusColor(booking.status) as "success" | "warning" | "default"}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Адрес:</strong> {booking.address || 'Адрес не указан'}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <EventIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Дата:</strong> {formatDate(booking.reserved_on)}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Время:</strong> {formatTimeInterval(booking.reserved_on, office?.duration.duration_without_pause || 60)}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AttachMoneyIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Цена:</strong> {booking.totalPrice ? formatPrice(booking.totalPrice) : 'Не указана'}</Typography>
        </Box>

        {booking.booking_comment && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <CommentIcon sx={{ mr: 1, mt: 0.5, color: 'text.secondary' }} />
            <Typography><strong>Комментарий:</strong> {booking.booking_comment}</Typography>
          </Box>
        )}

        {/* Добавляем отображение информации о клиенте */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>Информация о клиенте</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography><strong>Имя:</strong> {booking.clientName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography><strong>Телефон:</strong> {booking.clientPhone}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography><strong>Email:</strong> {booking.clientEmail}</Typography>
          </Box>
        </Box>

        {booking.status === 'NEW' && booking.passes && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Код доступа</Typography>
            {Object.entries(booking.passes).map(([deviceId, pass]) => (
              <AccessCodeStatus 
                key={deviceId} 
                pass={pass} 
                bookingCreatedAt={new Date(booking.createdAt)}
              />
            ))}
          </Box>
        )}

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
          <Button variant="contained" onClick={() => navigate('/bookings')} sx={{ mb: { xs: 1, sm: 0 } }}>Назад к бронированиям</Button>
          {booking.status !== 'CANCELLED' && (
            <Button variant="contained" color="error" onClick={() => setOpenCancelDialog(true)}>Отменить</Button>
          )}
        </Box>
      </Paper>

      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>Отмена бронирования</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Пожалуйста, укажите причину отмены бронирования:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="cancel-comment"
            label="Комментарий"
            type="text"
            fullWidth
            variant="outlined"
            value={actionComment}
            onChange={(e) => {
              setActionComment(e.target.value);
              setCommentError('');
            }}
            error={!!commentError}
            helperText={commentError}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenCancelDialog(false);
              setActionComment('');
              setCommentError('');
            }}
            disabled={isCancelling}
          >
            Не отменять
          </Button>
          <Button 
            onClick={handleCancel} 
            color="error"
            disabled={isCancelling}
            sx={{ minWidth: 120 }} // Устанавливаем минимальную ширину
          >
            {isCancelling ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Отменить'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookingDetails;
